<script lang="ts">
import type { GetImageResult } from "astro";

export let image: GetImageResult;
export let alt: string;
export let href: string | null;
export const isLast: boolean = false;
export let toggle: () => void;
</script>

<div class="relative">
    <a {href} on:click={toggle}>
        <img class="w-auto py-6" src={image.src} {alt} />
    </a>
    {#if !isLast}
        <div
            class="absolute inset-x-0 bottom-0 h-[1px] w-3/4 bg-gray-400 left-1/2 transform -translate-x-1/2"
        ></div>
    {/if}
</div>
